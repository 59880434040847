<style lang="less" scoped>
  @s16: 16px;
  @s8: 8px;

  .content{
    background: white;
    border-radius: 8px;
    height: 100%;
    margin-right: @s16;
    padding: 26px 24px;
    box-sizing: border-box;
    overflow-x: scroll;
    .title{
      font-size: 16px; font-weight: 600; line-height: 28px; display: flex; align-items: center;
      .switch{
        margin-left: @s8;
        width: 48px;
      }
      /deep/.el-switch__core{
        height: 28px; border-radius: 14px; width: 48px !important;
        &:after{ width: 22px; height: 22px; top: 2px; left: 2px; box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1); }
      }
      /deep/.el-switch.is-checked .el-switch__core{
        &:after{ left: 100%; margin-left: -24px; }
      }
    }
    .tips{
      padding: @s8 0;
      li{
        color: #666; line-height: 20px; font-size: 14px; padding: @s8 0;
        span{ background: #FFECE5; color: #F6891E; margin-right: @s8; display: inline-block; border-radius: 50%; width: 18px; line-height: 18px; text-align: center; }
      }
    }
    .tips-img{ width:1145px; margin-top: @s8; margin-right: 24px; }

  }
</style>

<style lang="less">
  .commission-dialog{
    .el-dialog__header{ display: none; }
    .el-dialog__body{ background: white; border-radius: 5px; padding: 24px; }
    &-title{
      font-weight: 500; line-height: 24px; font-size: 16px; position: relative; padding-left: 30px; margin-bottom: 10px; color: #252931;
      img{ position: absolute; left: 0; top: 50%; width: 20px; margin-top: -10px; }
      i.close-btn{ color:#333; position: absolute; right: 0; top: 0; font-size: 20px; }
    }
    &-content{ font-size: 14px; line-height: 20px; font-weight: 400; padding-left: 30px; }
    &-footer{
      text-align: right; padding-top: 24px;
      button{
        margin-left: 16px; height: 32px; padding: 0 20px; border:solid 1px #DCDEE1; border-radius: 4px; background: white; font-size: 14px; color: #222;
        &:first-child{margin-left: 0;}
        &.confirm-btn{ background: #FF791F; color: white; border: none; }
      }
    }
  }
</style>

<template>
  <div class="content" v-loading="loading">
    <div class="title">
      开通退货运费抽佣
      <el-switch
          :value="open"
          class="switch"
          active-color="#F6891E"
          inactive-color="#f3f4f3"
          @change="switchChange"
      />
    </div>
    <ul class="tips">
      <li><span>1</span>开通后对您店铺的退货单信息进行抓取。</li>
      <li><span>2</span>退货单的物流单号来自于【昕庭速寄】服务，该笔订单到达退货地址并签收成功，您将获得相应的抽佣奖励。</li>
    </ul>
    <img class="tips-img" :src="OSS + '/open_server_guide_img.png?v=2'" alt="tips"/>
    <!-- tips -->
    <div class="commission-dialog">
      <el-dialog
          :visible="tipsDialog.show"
          width="480px">
        <template>
          <div>
            <div class="commission-dialog-title">
              {{tipsDialog.title}}
              <img :src="OSS + '/info1211.png'" alt="tips"/>
              <i class="el-icon-close close-btn" @click="tipsDialog.show = false"></i>
            </div>
            <div class="commission-dialog-content">{{tipsDialog.content}}</div>
            <div class="commission-dialog-footer">
              <button class="confirm-btn" v-if="tipsDialog.next" @click="onToRouterBase">立即前往</button>
              <button class="confirm-btn" v-if="tipsDialog.verify" @click="submitRebateSwitch({ switch: 2 })">确定</button>
              <button v-if="tipsDialog.cancel" @click="tipsDialog.show = false">{{ count > 0 ? '知道了': '取消' }}</button>
            </div>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { rebateSwitch } from '@/api/returnOrder'

export default {
  name: "details",
  data() {
    return {
      open      : false,
      toast     : 1,
      count     : 0,
      loading   : false,
      tipsDialog: {
        show    : false,
        title   : "",
        content : "",
        cancel  : false,
        verify  : false,
        next    : false
      }
    };
  },
  methods: {
    switchChange(e){
      let { toast, count } = this
      // if(e && toast){
        // this.tipsDialog = {
        //   show    : true,
        //   title   : "提示",
        //   content : "请前往基础信息页面完善您的个人信息，产生的佣金将直接打入与【昕庭速寄】小程序中身份ID绑定的返佣账户内。",
        //   cancel  : true,
        //   verify  : false,
        //   next    : true
        // }
      // }
      if(!e && count > 0){
        this.tipsDialog = {
          show    : true,
          title   : "确认关闭服务？",
          content : `您店铺产生的退货单中，有${count}笔属于【昕庭速寄】的订单目前尚未成功签收，无法关闭。`,
          cancel  : true,
          verify  : false,
          next    : false
        }
        return;
      }
      if(!e){
        this.tipsDialog = {
          show    : true,
          title   : "确认关闭服务？",
          content : "关闭后，如果您店铺产生的退货单中包含通过【昕庭速寄】下单的用户，您将不会获得相应的佣金。",
          cancel  : true,
          verify  : true,
          next    : false
        }
        return;
      }
      this.submitRebateSwitch({ switch: 1 })
    },
    // 完善基本资料
    onToRouterBase(){
      router.push('baseInfo')
    },
    // submit
    submitRebateSwitch(params){
      rebateSwitch(params || {}).then(res => {
        let { code, data } = res;
        if(code === 0){
          this.open  = data.switch === 1;
          this.toast = data.toast
          this.count = data.count
          // 开启成功
          if(params){
            this.$message.success(data.switch === 1 ? '开启成功' : '关闭成功')
            // 如果有弹窗关闭弹窗
            if(this.tipsDialog.show) this.tipsDialog.show = false
          }
          // 初始化
          if(data.switch === 1 && data.toast === 1 && !params) {
            this.tipsDialog = {
              show    : true,
              title   : "提示",
              content : "请前往基础信息页面完善您的个人信息，产生的佣金将直接打入与【昕庭速寄】小程序中身份ID绑定的返佣账户内。",
              cancel  : true,
              verify  : false,
              next    : true
            }
          }
        }
      }).catch(err =>{
        this.$message.error(err.message);
      })
    }
  },
  mounted() {
    this.submitRebateSwitch()
  }
}
</script>